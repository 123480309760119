import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Page from "components/pages/front/Page";

type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { page, categories, footers } = pageContext;

  React.useEffect(() => {}, []);

  return (
    <>
      {page && (
        <GatsbySeo
          title={`${page.title} 舵オンライン │ 船遊びの情報サイト`}
          description="舵オンラインは、『Kazi』、『ボート倶楽部』を刊行する舵社が運営する、船遊びに関する情報サイト。ヨット、ボート、ボート釣り、クルージング、ボート免許、カヌーなど、プレジャーボートや海に関するさまざまなニュースをお届けします。"
          openGraph={{
            url: `https://kazi-online.com/${page.root}/${page.slug}`,
            title: `${page.title} 舵オンライン │ 船遊びの情報サイト`,
            description: page.description,
            type: "website",
            site_name: `舵オンライン │ 船遊びの情報サイト`,
            images: [
              {
                url:
                  "https://kazi-online.com/images/KAZIonline_SHARE_1200×630pix.jpg",
                alt: "舵オンライン │ 船遊びの情報サイト（舵社）",
              },
            ],
          }}
        />
      )}
      <Helmet>
        <title>{`舵オンライン │ 船遊びの情報サイト ${page.title}`}</title>
      </Helmet>
      <Layout footers={footers}>
        <Page page={page} categories={categories} />
      </Layout>
    </>
  );
}
