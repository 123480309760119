import React, { useEffect } from "react";
import Container from "components/atoms/Container";
import { Helmet } from "react-helmet";
import HtmlSection from "components/molecures/Sections/HtmlSection";
import {
  UpdatePageInput as PageType,
  UpdateCategoryInput as Category,
} from "API";
import Header from "components/uikit/Header/Header";
import ResponsiveComponent from "components/atoms/ResponsiveComponent";

type Props = {
  page: PageType;
  categories: Category[];
};

const style = `
  .sm {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  .readmore {
    overflow: hidden;
    transition: height 1s ease;
  }
`;

export default function Page(props: Props) {
  const { page } = props;
  useEffect(() => {
    if (typeof window !== "undefined") {
      const $ = require("jquery");
      require("readmore-js");

      $(document).ready(function () {
        $(".readmore").readmore({
          speed: 1,
          collapsedHeight: 40,
          moreLink: '<a href="#" class="sm">続きを読む</a>',
          lessLink: '<a href="#" class="sm">閉じる</a>',

          afterToggle: function (trigger: any, element: any, expanded: any) {
            if (!expanded) {
              // "閉じる"リンクがクリックされた場合
              const elementId = $(element).attr("id");

              if (elementId) {
                const targetElement = $(`#${elementId}.readmore`);
                const targetParentElement = targetElement;
                if (targetParentElement.length) {
                  const elementOffset = targetParentElement.offset().top - 300;

                  // スクロールアニメーション
                  $("html, body").animate(
                    { scrollTop: elementOffset },
                    { duration: 10 }
                  );
                }
              }
            }
          },
        });
      });
    }
  }, []);

  return (
    <>
      <Header
        color="white"
        brand="Kazi ONLINE"
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white",
        }}
      />

      <Helmet>
        <style type="text/css">{style}</style>
      </Helmet>

      <ResponsiveComponent
        sp={
          <Container
            style={{
              paddingTop: "247px",
            }}
          >
            <HtmlSection data={page.contents} />
          </Container>
        }
        pc={
          <Container
            style={{
              paddingTop: "203px",
            }}
          >
            <HtmlSection data={page.contents} />
          </Container>
        }
      />
    </>
  );
}
